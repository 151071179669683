import Section, {
    ATTRIBUTE_SECTION_ID,
    ATTRIBUTE_SECTION_INITIALIZED,
    ATTRIBUTE_SECTION_TYPE
} from "@packages/sections/section";

import importSection from "@/importer";

export const EVENT_SHOPIFY_INSPECTOR_ACTIVATE          = "shopify:inspector:activate";
export const EVENT_SHOPIFY_INSPECTOR_DEACTIVATE        = "shopify:inspector:deactivate";
export const EVENT_SHOPIFY_SECTION_LOAD                = "shopify:section:load";
export const EVENT_SHOPIFY_SECTION_UNLOAD              = "shopify:section:unload";
export const EVENT_SHOPIFY_SECTION_SELECT              = "shopify:section:select";
export const EVENT_SHOPIFY_SECTION_DESELECT            = "shopify:section:deselect";
export const EVENT_SHOPIFY_SECTION_REORDER             = "shopify:section:reorder";
export const EVENT_SHOPIFY_BLOCK_SELECT                = "shopify:block:select";
export const EVENT_SHOPIFY_BLOCK_DESELECT              = "shopify:block:deselect";
export const EVENT_SHOPIFY_SECTION_LOAD_FAILED         = "shopify:section:load-failed";

window.Shopify = window.Shopify || {};
window.Shopify.sections = window?.Shopify?.theme?.sections || {};
window.Shopify.sections.registered = window?.Shopify?.sections?.registered || {};
window.Shopify.sections.instances = window?.Shopify?.sections?.instances || {};
window.Shopify.loadFeatures = () => {};

export const registered = window?.Shopify?.sections?.registered || {};
export let instances = window?.Shopify?.sections?.instances || {};

export const registerCallbackOnUserInteractionEvents = [
    'touchstart',
    'click',
    'mouseover',
    'keydown'
];

export function getInstances() {
    return Object.values(instances || {});
}

export function getInstanceById(id) {
    return instances[id] || null;
}

function normalizeType(types) {
    // If '*' then fetch all registered section types
    if (types === '*') {
        types = Object.keys(registered);

        // If a single section type string is passed, put it in an array
    } else if (typeof types === 'string') {
        types = [types];

        // If single section constructor is passed, transform to array with section
        // type string
    } else if (types instanceof Section) {
        types = [types.prototype.type];

        // If array of typed section constructors is passed, transform the array to
        // type strings
    } else if (Array.isArray(types) && types[0].constructor === Section) {
        types = types.map((TypedSection) => {
            return TypedSection.prototype.type;
        });
    }

    return types?.map((type) => {
        return type?.toLowerCase();
    }) || [];
}

function normalizeContainers(containers) {
    // Nodelist with entries
    // eslint-disable-next-line no-prototype-builtins
    if (NodeList.prototype.isPrototypeOf(containers) && containers.length > 0) {
        containers = Array.prototype.slice.call(containers);

        // Empty Nodelist
    } else if (
        // eslint-disable-next-line no-prototype-builtins
        NodeList.prototype.isPrototypeOf(containers) &&
        containers.length === 0
    ) {
        containers = [];

        // Handle null (document.querySelector() returns null with no match)
    } else if (containers === null) {
        containers = [];

        // Single DOM element
    } else if (!Array.isArray(containers) && containers instanceof Element) {
        containers = [containers];
    }

    return containers;
}

export async function isSectionRegistered(type) {
    if (typeof type !== 'string') {
        throw new TypeError(
            'Theme Sections: The first argument for .register must be a string that specifies the type of the section being registered'
        );
    }

    return typeof registered[type] !== 'undefined';
}


export async function register(type, TypedSection = Section) {
    if (typeof type !== 'string') {
        throw new TypeError(
            'Theme Sections: The first argument for .register must be a string that specifies the type of the section being registered'
        );
    }

    const sectionRegistered = await isSectionRegistered(type);
    if (!!sectionRegistered) {
        throw new Error(
            `Theme Sections: A section of type "${ 
            type 
            }" has already been registered. You cannot register the same section type twice`
        );
    }

    registered[type] = TypedSection;

    return registered[type];
}

export async function asyncRegister(sectionType) {
    const sectionRegistered = await isSectionRegistered(sectionType);
    if (!!sectionRegistered) {
        return;
    }

    const { default: Section } = await importSection(sectionType);
    if (!Section) {
        return;
    }

    await register(sectionType, Section);
}

export async function unregister(types) {
    types = normalizeType(types);
    if (!types) {
        return;
    }

    if (!types?.length) {
        return;
    }

    types?.forEach((type) => {
        delete registered[type];
    });
}

export async function load(types, containers = []) {
    types = normalizeType(types);
    if (!types) {
        return;
    }

    if (!types?.length) {
        return;
    }

    if (!containers?.length) {
        containers = document.querySelectorAll(`[${  ATTRIBUTE_SECTION_TYPE  }]`);
    }

    containers = normalizeContainers(containers);
    if (!containers) {
        return;
    }

    if (!containers?.length) {
        return;
    }

    const loadedInstances = await Promise.all(types?.reduce((promises, type) => {
        const TypedSection = registered[type];
        if (!TypedSection) {
            return promises;
        }

        return [
            ...(promises || []),
            ...(containers?.filter((container) => {
                const sectionId = container?.getAttribute(ATTRIBUTE_SECTION_ID);
                if (!sectionId) {
                    return false;
                }

                const instance = getInstanceById(sectionId);
                if (!!instance) {
                    return false;
                }

                const sectionType = container?.getAttribute(ATTRIBUTE_SECTION_TYPE);
                if (!sectionType) {
                    return false;
                }

                return sectionType === type;
            })?.map((container) => {
                return new TypedSection(container);
            })?.map((section) => {
                if (!section?.load) {
                    return null;
                }

                return section.load();
            })?.filter((promise) => {
                return !!promise;
            }) || []),
        ];
    }, []));

    instances = {
        ...(instances || {}),
        ...(loadedInstances?.reduce((loadedInstances, instance) => {
            const id = instance?.id;
            if (!id) {
                return loadedInstances;
            }

            return {
                ...(loadedInstances || {}),
                [id]: instance,
            };
        }, {}) || {}),
    };
}

export function unload(types, containers = []) {
    types = normalizeType(types);
    if (!types) {
        return;
    }

    if (!types?.length) {
        return;
    }

    if (typeof containers === 'undefined') {
        containers = document.querySelectorAll(`[${  ATTRIBUTE_SECTION_TYPE  }]`);
    }

    containers = normalizeContainers(containers);
    if (!containers) {
        return;
    }

    if (!containers?.length) {
        return;
    }

    types?.forEach((type) => {
        const TypedSection = registered[type];
        if (!TypedSection) {
            return;
        }

        containers?.filter((container) => {
            const sectionId = container?.getAttribute(ATTRIBUTE_SECTION_ID);
            if (!sectionId) {
                return false;
            }

            const instance = instances[sectionId];
            if (!!instance) {
                return false;
            }

            const sectionType = container?.getAttribute(ATTRIBUTE_SECTION_TYPE);
            if (!sectionType) {
                return false;
            }

            if (sectionType !== type) {
                return false;
            }

            return false;
        })?.forEach(async (container) => {
            const sectionId = container?.getAttribute(ATTRIBUTE_SECTION_ID);
            if (!sectionId) {
                return;
            }

            const instance = instances[sectionId];
            if (!instance) {
                return;
            }

            await instance?.onUnload();

            delete instances[sectionId];
        }, {});
    });
}

export function getDOMSections() {
    const sections = Array.from(document.querySelectorAll(`[${  ATTRIBUTE_SECTION_TYPE  }]`));
    if (!sections) {
        return {
            legacySections: [],
            immediateSections: [],
            interactionSections: [],
            inViewSections: []
        };
    }

    if (!sections?.length) {
        return {
            legacySections: [],
            immediateSections: [],
            interactionSections: [],
            inViewSections: []
        };
    }

    const uninitializedSections = sections?.filter((section) => {
        return section?.getAttribute(ATTRIBUTE_SECTION_INITIALIZED) !== "true";
    });

    if (!uninitializedSections) {
        return {
            legacySections: [],
            immediateSections: [],
            interactionSections: [],
            inViewSections: []
        };
    }

    if (!uninitializedSections?.length) {
        return {
            legacySections: [],
            immediateSections: [],
            interactionSections: [],
            inViewSections: []
        };
    }

    return uninitializedSections?.reduce((sections, section) => {
        const sectionLoading = section?.dataset?.sectionLoading;
        if (!sectionLoading) {
            return {
                ...(sections || {}),
                immediateSections: [
                    ...(sections?.immediateSections || []),
                    section,
                ]
            };
        }

        switch (sectionLoading?.toLowerCase()) {
            case "interaction": {
                return {
                    ...(sections || {}),
                    interactionSections: [
                        ...(sections?.interactionSections || []),
                        section,
                    ]
                };
            }
            case "in-view": {
                return {
                    ...(sections || {}),
                    inViewSections: [
                        ...(sections?.inViewSections || []),
                        section,
                    ]
                };
            }
            case "legacy": {
                return {
                    ...(sections || {}),
                    legacySections: [
                        ...(sections?.legacySections || []),
                        section,
                    ]
                };
            }
            default: {
                return {
                    ...(sections || {}),
                    immediateSections: [
                        ...(sections?.immediateSections || []),
                        section,
                    ]
                };
            }
        }
    }, {
        legacySections: [],
        immediateSections: [],
        interactionSections: [],
        inViewSections: []
    }) || {
        legacySections: [],
        immediateSections: [],
        interactionSections: [],
        inViewSections: []
    };
}

async function registerAsyncSections() {
    const intersectionObserver = new IntersectionObserver(async (entries) => {
        if (!entries) {
            return;
        }

        if (!entries?.length) {
            return;
        }

        await Promise.all(entries?.map(async (entry) => {
            const intersecting = entry?.isIntersecting;
            if (!intersecting) {
                return;
            }

            const container = entry?.target;
            if (!container) {
                return;
            }

            if (container?.getAttribute(ATTRIBUTE_SECTION_INITIALIZED) === "true") {
                intersectionObserver?.unobserve(container);
                return;
            }

            const sectionType = container?.getAttribute(ATTRIBUTE_SECTION_TYPE);
            if (!sectionType) {
                intersectionObserver?.unobserve(container);
                return;
            }

            const sectionId = container?.getAttribute(ATTRIBUTE_SECTION_ID);
            if (!sectionId) {
                intersectionObserver?.unobserve(container);
                return;
            }

            try {
                await asyncRegister(sectionType).catch(() => {});
                await load([sectionType], [container]);

                container?.setAttribute(ATTRIBUTE_SECTION_INITIALIZED, "true");
            } catch (error) {
                document.dispatchEvent(new CustomEvent(EVENT_SHOPIFY_SECTION_LOAD_FAILED, {
                    detail: {
                        sectionId,
                        sectionType,
                    }
                }));
            } finally {
                intersectionObserver?.unobserve(container);
            }
        }) || []);
    }, {
        root: null,
        rootMargin: '0px',
        threshold: 0,
    });

    const mutationObserver = new MutationObserver((mutations) => {
        if (!mutations) {
            return;
        }

        if (!mutations?.length) {
            return;
        }

        mutations?.forEach((mutation) => {
            const type = mutation?.type;
            if (type !== "childList") {
                return;
            }

            const addedNodes = mutation?.addedNodes;
            if (!addedNodes) {
                return;
            }

            if (!addedNodes?.length) {
                return;
            }

            addedNodes?.forEach((node) => {
                if (node?.nodeType !== 1) {
                    return;
                }

                if (!node?.matches(`[${ATTRIBUTE_SECTION_TYPE}]`)) {
                    return;
                }

                if (node?.getAttribute(ATTRIBUTE_SECTION_INITIALIZED) === "true") {
                    return;
                }

                intersectionObserver?.observe(node);
            });
        });
    });

    mutationObserver?.observe(document?.body, {
        childList: true,
        subtree: true,
    });

    const {
        inViewSections
    } = getDOMSections();

    if (!inViewSections) {
        return;
    }

    if (!inViewSections?.length) {
        return;
    }

    inViewSections?.forEach((section) => {
        intersectionObserver?.observe(section);
    });
}

async function registerInteractionSections() {
    const {
        interactionSections,
    } = getDOMSections();

    if (!interactionSections) {
        return;
    }

    if (!interactionSections?.length) {
        return;
    }

    const types = [
        ...new Set(interactionSections?.map((section) => {
            return section?.dataset?.sectionType;
        })?.filter((type) => {
            return !!type;
        }) || []),
    ];

    if (!types) {
        return;
    }

    if (!types?.length) {
        return;
    }

    const registeredTypes = await Promise.all(types?.map((type) => {
        return asyncRegister(type).then(() => {
            return type;
        }).catch(() => {
            return null;
        });
    })).then((types) => {
        return types?.filter((type) => {
            return !!type;
        }) || [];
    });

    if (!registeredTypes) {
        return;
    }

    if (!registeredTypes?.length) {
        return;
    }

    await load(registeredTypes);
}
async function registerSyncSections() {
    const {
        immediateSections,
    } = getDOMSections();

    if (!immediateSections) {
        return;
    }

    if (!immediateSections?.length) {
        return;
    }

    const types = [
        ...new Set(immediateSections?.map((section) => {
            return section?.dataset?.sectionType;
        })?.filter((type) => {
            return !!type;
        }) || []),
    ];

    if (!types) {
        return;
    }

    if (!types?.length) {
        return;
    }

    const registeredTypes = await Promise.all(types?.map((type) => {
        return asyncRegister(type).then(() => {
            return type;
        }).catch(() => {
            return null;
        });
    })).then((types) => {
        return types?.filter((type) => {
            return !!type;
        }) || [];
    });

    if (!registeredTypes) {
        return;
    }

    if (!registeredTypes?.length) {
        return;
    }

    await load(registeredTypes);
}

async function asyncLoad() {
    return registerAsyncSections();
}

let userInteraction = false;
async function loadOnceOnUserInteraction(callback = () => {}) {
    if (!!userInteraction) {
        return;
    }

    if (typeof callback !== "function") {
        return;
    }

    await callback();

    userInteraction = true;
}

export function registerCallbackOnUserInteraction(asyncLoad) {
    if (!registerCallbackOnUserInteractionEvents) {
        return;
    }

    if (!registerCallbackOnUserInteractionEvents?.length) {
        return;
    }

    const registerCallbackOnUserInteractionCallback = async () => {
        await loadOnceOnUserInteraction(asyncLoad);
    };

    registerCallbackOnUserInteractionEvents?.forEach((eventName) => {
        document.addEventListener(eventName, registerCallbackOnUserInteractionCallback, {
            once: true,
            passive: false,
        });
    });
}

export async function initCustomizerEvents() {
    if (!window?.Shopify?.designMode) {
        return;
    }

    document.addEventListener(EVENT_SHOPIFY_SECTION_LOAD, async (event) => {
        const sectionId = event?.detail?.sectionId;
        if (!sectionId) {
            return;
        }

        const container = event?.target?.querySelector(`[${ATTRIBUTE_SECTION_ID}="${sectionId}"]`);
        if (!container) {
            return;
        }

        const sectionType = container?.getAttribute(ATTRIBUTE_SECTION_TYPE);
        if (!sectionType) {
            return;
        }

        const instance = getInstanceById(sectionId);
        if (!instance) {
            return;
        }

        await instance?.onLoad();
    });

    document.addEventListener(EVENT_SHOPIFY_SECTION_UNLOAD, async (event) => {
        const sectionId = event?.detail?.sectionId;
        if (!sectionId) {
            return;
        }

        const container = event?.target?.querySelector(`[${ATTRIBUTE_SECTION_ID}="${sectionId}"]`);
        if (!container) {
            return;
        }

        const sectionType = container?.getAttribute(ATTRIBUTE_SECTION_TYPE);
        if (!sectionType) {
            return;
        }

        const instance = getInstanceById(sectionId);
        if (!instance) {
            return;
        }

        await instance.onUnload();
    });

    document.addEventListener(EVENT_SHOPIFY_SECTION_SELECT, async (event) => {
        const sectionId = event?.detail?.sectionId;
        if (!sectionId) {
            return;
        }

        const instance = getInstanceById(sectionId);
        if (!instance) {
            return;
        }

        if (!(instance instanceof Section)) {
            return;
        }

        await instance.onSelect(event);
    });

    document.addEventListener(EVENT_SHOPIFY_SECTION_DESELECT, async (event) => {
        const sectionId = event?.detail?.sectionId;
        if (!sectionId) {
            return;
        }

        const instance = getInstanceById(sectionId);
        if (!instance) {
            return;
        }

        if (!(instance instanceof Section)) {
            return;
        }

        await instance.onDeselect(event);
    });

    document.addEventListener(EVENT_SHOPIFY_BLOCK_SELECT, async (event) => {
        const sectionId = event?.detail?.sectionId;
        if (!sectionId) {
            return;
        }

        const instance = getInstanceById(sectionId);
        if (!instance) {
            return;
        }

        if (!(instance instanceof Section)) {
            return;
        }

        await instance.onBlockSelect(event);
    });

    document.addEventListener(EVENT_SHOPIFY_BLOCK_DESELECT, async (event) => {
        const sectionId = event?.detail?.sectionId;
        if (!sectionId) {
            return;
        }

        const instance = getInstanceById(sectionId);
        if (!instance) {
            return;
        }

        if (!(instance instanceof Section)) {
            return;
        }

        await instance.onBlockDeselect(event);
    });
}

export async function asyncLoadOnUserInteraction() {
    await registerSyncSections();

    registerCallbackOnUserInteraction(async () => {
        await registerInteractionSections();
        await registerAsyncSections();

        document.body.classList.add('interacted');
    });

    await initCustomizerEvents();
}

export default {
    register,
    asyncLoad,
    load,
    asyncLoadOnUserInteraction,
    unregister,
    unload,
    getInstances,
    getInstanceById,
    registerCallbackOnUserInteraction,
};
