import Bugsnag, { PREFIX } from '@/helpers/bugsnag';

const selectors = {
    video: '[data-video]'
};

const classes = {
    loaded: 'video--loaded'
};

let userInteraction = false;

const settings = {
    loaded(video) {
        const autoplay = video?.dataset?.autoplay;

        video?.classList?.add(classes?.loaded);

        if (typeof autoplay === "undefined") {
            return;
        }

        video?.setAttribute("autoplay", true);
        video?.play();
    },
};

async function loadOnceOnUserInteraction(cb) {
    if (!!userInteraction) {
        return;
    }

    await cb();
    userInteraction = true;
}

const registerCallbackOnUserInteraction = (asyncLoad) => {
    const events = ['touchstart', 'mouseover', 'keydown'];
    if (!events) {
        return;
    }

    if (!events?.length) {
        return;
    }

    events?.forEach((event) => {
        document.addEventListener(event, async () => {
            await loadOnceOnUserInteraction(asyncLoad);
        }, {
            once: true
        });
    });
};

document.addEventListener("DOMContentLoaded", () => {
    const videos = document.querySelectorAll(selectors?.video);
    if (!videos) {
        return;
    }

    if (!videos?.length) {
        return;
    }

    registerCallbackOnUserInteraction(async () => {
        try {
            const { default: lozad } = await import("lozad");
            if (!lozad) {
                return;
            }

            const observer = lozad(selectors?.video, settings);
            if (!observer) {
                return;
            }

            observer?.observe();

            const mutationObserver = new MutationObserver((mutationsList) => {
                for (const mutation of mutationsList) {
                    if (mutation?.type !== 'childList') {
                        return;
                    }

                    const addedNodes = mutation?.addedNodes;
                    if (!addedNodes) {
                        return;
                    }

                    if (!addedNodes?.length) {
                        return;
                    }

                    addedNodes?.forEach((node) => {
                        if (node?.nodeType !== 1) {
                            return;
                        }

                        if (!!node?.matches(selectors?.video)) {
                            // New `.lozad` element added, observe it with Lozad
                            observer?.triggerLoad(node);

                            observer.observe(node, settings);

                            return;
                        }

                        const elements = Array.from(node?.querySelectorAll(selectors?.video));
                        if (!elements) {
                            return;
                        }

                        if (!elements?.length) {
                            return;
                        }

                        elements?.forEach((element) => {
                            observer?.triggerLoad(element);

                            observer?.observe(element, settings);
                        });
                    });
                }
            });

            mutationObserver?.observe(document.body, {
                childList: true,
                subtree: true,
            });
        } catch (error) {
            try {
                Bugsnag.notify(new Error(`[${PREFIX}] Unable to load video`), (event) => {
                    event.severity = 'error';

                    event.addMetadata('parsedError', {
                        response: error?.data || error?.response,
                        error,
                    });
                });
            } catch (error) {}
        }
    });
});
